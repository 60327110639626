
// 波斯语配置
// 只有配置了路由 该页面才会有波斯语语言选择
// rtl 为 false  表示仅处理波斯语翻译 不考虑布局变化
export const faConfig = [
  // 首页
  {
    path: '/',
    rtl: true
  },
  /**
   注册登录
   */
   {
    path: '/login',
    rtl: true
   },
   {
    path: '/register',
    rtl: true
  },
  {
    path: '/forget',
    rtl: true
  },
  /**
   WXT
   /wxt
   */
  // {
  //   path: '/wxt',
  //   rtl: true
  // },
  // /**
  //  WE-Launch
  //  /WE-Launch
  //  */
  // {
  //   path: '/WE-Launch',
  //   rtl: true
  // },
  // /**
  //  个人中心
  //  /account
  //   /account/setting
  //   /account/setting/pwd
  //   /account/setting/bindmobile
  //   /account/setting/history
  //   /modifyverification
  //   /account/setting/modifygoogle
  //   /account/setting/devices
  //   /account/welfare
  //   /account/preference
  //  */
  // {
  //   path: '/account',
  //   rtl: true
  // },
  // {
  //   path: '/account/setting',
  //   rtl: true
  // },
  // {
  //   path: '/account/setting/pwd',
  //   rtl: true
  // },
  // {
  //   path: '/account/setting/bindmobile',
  //   rtl: true
  // },
  // {
  //   path: '/account/setting/history',
  //   rtl: true
  // },
  // {
  //   path: '/modifyverification',
  //   rtl: true
  // },
  // {
  //   path: '/account/setting/modifygoogle',

  //   rtl: true
  // },
  // {
  //   path: '/account/setting/devices',
  //   rtl: true
  // },
  // {
  //   path: '/account/welfare',
  //   rtl: true
  // },
  // {
  //   path: '/account/preference',
  //   rtl: true
  // },

  // /**
  //  服务
  //  */
  // {
  //   path: '/support/activityDetail',
  //   rtl: false
  // },
  // {
  //   path: '/support/aliyunVerfiy',
  //   rtl: false
  // },
  // {
  //   path: '/support/contractProtocol',
  //   rtl: false
  // },
  // {
  //   path: '/support/index',
  //   rtl: false
  // },
  // {
  //   path: '/support/installationTutorial',
  //   rtl: false
  // },
  // {
  //   path: '/support/internaljumpdl',
  //   rtl: false
  // },
  // {
  //   path: '/support/iosLoginSupportInfo',
  //   rtl: false
  // },
  // {
  //   path: '/support/jumpdl',
  //   rtl: false
  // },
  // {
  //   path: '/support/rate',
  //   rtl: false
  // },
  // {
  //   path: '/support/registrationTutorial',
  //   rtl: false
  // },
  // {
  //   path: '/support/specialDownload-v7k6',
  //   rtl: false
  // },
  // {
  //   path: '/support/specialDownload',
  //   rtl: false
  // },
  // /**
  //  魔方跳转页面
  //  */
  // {
  //   path: '/job',
  //   rtl: false
  // },
  // {
  //   path: '/school',
  //   rtl: false
  // },
  // {
  //   path: '/documentary',
  //   rtl: false
  // },
  // {
  //   path: '/protectfund',
  //   rtl: false
  // },
  // {
  //   path: '/spokesperson',
  //   rtl: false
  // },
  // {
  //   path: '/affiliate',
  //   rtl: false
  // },
  // {
  //   path: '/faq',
  //   rtl: false
  // },
  // {
  //   path: '/official-verification',
  //   rtl: false
  // },
  // {
  //   path: '/feedback',
  //   rtl: false
  // },
  // {
  //   path: '/global-strategy',
  //   rtl: false
  // },
  // {
  //   path: '/news-report',
  //   rtl: false
  // },
  // /**
  //  footer底部跳转页面
  {
    path: '/job',
    rtl: false
  },
  {
    path: '/useragent',
    rtl: false
  },
  {
    path: '/aboutus',
    rtl: false
  },
  {
    path: '/tokens',
    rtl: false
  },
  {
    path: '/Media-kit',
    rtl: false
  },
  {
    path: '/community',
    rtl: false
  },
  {
    path: '/quotelist',
    rtl: false
  },
  {
    path: '/proof-of-reserves',
    rtl: false
  },
  {
    path: '/download',
    rtl: false
  },
  {
    path: '/affiliate',
    rtl: false
  },
  {
    path: '/how-to-buy',
    rtl: false
  },
  {
    path: '/sitemap',
    rtl: false
  },
  {
    path: '/feedback',
    rtl: false
  },
  {
    path: '/faq',
    rtl: false
  },
   // 订单
   {
    path: '/transac',
    rtl: true,
  },
  {
    path: '/transac/contract',
    rtl: true,
  },
  {
    path: '/transac/newcontract',
    rtl: true,
  },
  // 合约相关
  {
    path: '/futures/introduction/futures-pro',
    rtl: false,
  },
  {
    path: '/futures',
    rtl: false,
  },
  {
    path: '/futures/demo-trading',
    rtl: false,
  },
  {
    path: '/futures/classic',
    rtl: false,
  },
  {
    path: '/futures/mock-trading',
    rtl: false,
  },
  /**
   合约信息
   */
  {
    path: '/futures/introduction/funding-rate',
    rtl: false,
  },
  {
    path: '/futures/introduction/funding-rate-classic',
    rtl: false,
  },
  {
    path: '/futures/introduction/position-history',
    rtl: false,
  },
  {
    path: '/futures/introduction/risk-limit',
    rtl: false,
  },
  {
    path: '/futures/introduction/risk-limit-classic',
    rtl: false,
  },
  // 跟单用户详情页
  {
    path: '/copy-trading/trader',
    rtl: false,
  },
  // 跟单
  {
    path: '/copytrading',
    rtl: false,
  },
  // 跟单pro
  {
    path: '/copy-trading',
    rtl: false,
  },
  // 现货相关
  // {
  //   path: '/trade',
  //   rtl: true,
  // },
  // 资产相关
  // {
  //   path: '/asset',
  //   rtl: false,
  // },
  // {
  //   path: '/asset/recharge',
  //   rtl: false,
  // },
  // {
  //   path: '/asset/withdraw',
  //   rtl: false,
  // },
  // {
  //   path: '/asset/transfer',
  //   rtl: false,
  // },
  // {
  //   path: '/record/exchange',
  //   rtl: false,
  // },
  // {
  //   path: '/record/contract',
  //   rtl: false,
  // },
  // {
  //   path: '/record/newcontract',
  //   rtl: false,
  // },
  // 站内信息
  {
    path: '/notice/activityDetail',
    rtl: false
  },
  {
    path: '/notice',
    rtl: false
  },
  {
    path: '/asset',
    rtl: true,
  },
  {
    path: '/asset/recharge',
    rtl: true,
  },
  {
    path: '/asset/withdraw',
    rtl: true,
  },
  {
    path: '/asset/transfer',
    rtl: true,
  },
  {
    path: '/record/exchange',
    rtl: true,
  },
  {
    path: '/record/contract',
    rtl: true,
  },
  {
    path: '/record/newcontract',
    rtl: true,
  },
]
