import _ from 'lodash'
import languageMapping, { langCodeMap, languageKeyMap } from '../config/languageMapping';
// import { faConfig } from '../config/faConfig.js'
import { faConfig } from '../web-base/config/faConfig.js';
import { isClient } from './envHelper.js';

export const mainAreaCode = '1'

export const enabledLanguageMapping = languageMapping.filter(item => item.enabled);
// 获取排序后多语言
export const getSortByPriority = () => enabledLanguageMapping.sort((a, b) => a.sortPirority - b.sortPirority);

export const defaultMapping = enabledLanguageMapping.find(item => item.default === true)
const isEqualIgnoreCase = (str1, str2) => {
  return str1.localeCompare(str2, undefined, { sensitivity: 'accent' }) === 0
}
export const getGeeTestLocale = (i18nLanguage) => {
  const item = enabledLanguageMapping.find(item =>
    _.some(item.localeCodes, localeCode => isEqualIgnoreCase(localeCode, i18nLanguage))
  )
  return item ? item.geeTestLocale : defaultMapping?.geeTestLocale ?? 'en'
}

export const getSupportLocaleByLanguageType = (langCode) => {
  const item = enabledLanguageMapping.find(item =>
    _.some(item.localeCodes, localeCode => isEqualIgnoreCase(localeCode, langCode))
  )
  return item ? item.supportLocale : defaultMapping?.supportLocale ?? 'en-us'
}

export const getLangByLocaleCode = (localeCode) => {
  // return localeCode if its type is language already
  const validTypes = /^(en|zh-TW|zh-CN|ko|vi|fa)$/
  if (validTypes.test(localeCode)) return localeCode

  const item = enabledLanguageMapping.find(item =>
    _.some(item.localeCodes, localeCodeEle => isEqualIgnoreCase(localeCodeEle, localeCode))
  )
  return item ? item.language : defaultMapping?.language ?? 'en'
}

export const getBackendLocaleCodeByLocaleCode = (localeCode) => {
  // 后端接受的语言类型必须为底线而非连字符
  // Otherwise, transform into LocalTypes
  const item = enabledLanguageMapping.find(item =>
    _.some(item.localeCodes, localeCodeItem => isEqualIgnoreCase(localeCodeItem, localeCode))
  )
  return item ? item.backendLocaleCode : defaultMapping?.backendLocaleCode ?? 'en_US'
}

export const getLangByBrowserLanguage = () => {
  const localeCode = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language
  return getLangByLocaleCode(localeCode)
}

export const getLangByUserCountry = (countryCode) => {
  const item = enabledLanguageMapping.find(item =>
    _.some(item.countryCodes, countryCodeEle => isEqualIgnoreCase(countryCodeEle, countryCode))
  )
  return item ? item.language : defaultMapping?.language ?? 'en'
}

export const getLangCodeByLang = (language) => {
  const item = enabledLanguageMapping.find(item => item.localeCodes.some(i => isEqualIgnoreCase(i, language)))
  return item ? item.langCode : defaultMapping?.langCode ?? 0
}

export const getLangCodeByLocaleCode = (localeCode) => {
  const item = enabledLanguageMapping.find(item =>
    _.some(item.localeCodes, localeCodeItem => isEqualIgnoreCase(localeCodeItem, localeCode))
  )
  return item ? item.langCode : defaultMapping?.langCode ?? 0
}

// 获取当前语言-设置区号
export const getLanguageSetAreaCode = (language) => {
  const defaultLng = language || getLangByBrowserLanguage()
  const item = enabledLanguageMapping.find(item => isEqualIgnoreCase(item.language, defaultLng))
  let areaCode = item ? item.areaCode : defaultMapping?.areaCode ?? '1'
  if (areaCode === '886') areaCode = '86'
  return areaCode
}

/**
 * 判断是否为 RTL 语言
 * 如果页面中使用可以不传path
 * @param {*} language
 * @param {*} path
 * @returns
 */
export const isRtlLanguage = (path, callOnServer) => {
  return isRtl({ path, faConfig, isClient: callOnServer ? true : isClient })
}

function findPathConfig(path, faConfig) {
  const isHomePage = path === `/`;

  return faConfig.find(item => {
    if (item.path === '/') {
      return isHomePage;
    }

    return path?.startsWith(item.path);
  });
}

export function isRtl(options) {
  const { path, faConfig, isClient } = options
  if (!isClient) {
    return false
  }
  // 如果不是波斯语 返回 false
  if (!path.startsWith(`/${languageKeyMap.fa}`)) {
    return false
  }
  // 路由配置波斯语是否仅翻译生效
  const pathWithoutLan = removeUrlLanguage(path)
  const pathConfig = findPathConfig(pathWithoutLan, faConfig)
  return pathConfig?.rtl ?? false;
}

export const getDirValue = (path) => {
  return isRtlLanguage(path, true) ? 'rtl' : 'ltr';
}

// language 集合
export const LanguageList = enabledLanguageMapping.map(item => item.language)

// 处理语言key的一致性 比如传入 zh 要转成 zh-CN
export const getLanguageKey = (language, defaultLanguage = 'en') => {
  const item = enabledLanguageMapping.find(item => item.reg.test(language))
  return item ? item.language : defaultLanguage ?? 'en'
}

// 基于路由配置获取语言列表
export const getLanguageListByRoute = (path) => {
  const isHomePage = path === `/` || LanguageList.some(item => path === `/${item}`);

  const pathWithoutLan = removeUrlLanguage(path)
  const showFA = faConfig.some(item => {
    if (item.path === '/') {
      return isHomePage;
    }
    return pathWithoutLan?.startsWith(item.path)
  });

  return showFA
    ? enabledLanguageMapping
    : enabledLanguageMapping.filter(item => item.langCode !== langCodeMap.fa);
}

const onCheckLanguage = (language, code) => {
  return languageMapping?.find(item => item.langCode == code)?.localeCodes?.includes(language) ?? false
}

// 通过code 获取 languageKey  0 => en
export const getLanguageKeyByLangCode = (langCode) => {
  return languageMapping?.find(item => item.langCode == langCode)?.language ?? languageKeyMap.en
}
export const checkLan = {
  isEn: (language) => {
    return onCheckLanguage(language, langCodeMap.en)
  },
  isZhCN: (language) => {
    return onCheckLanguage(language, langCodeMap.zhCN)
  },
  isKo: (language) => {
    return onCheckLanguage(language, langCodeMap.ko)
  },
  isVi: (language) => {
    return onCheckLanguage(language, langCodeMap.vi)
  },
  isZhTW: (language) => {
    return onCheckLanguage(language, langCodeMap.zhTW)
  },
  isFa: (language) => {
    return onCheckLanguage(language, langCodeMap.fa)
  }
}

const defaultBackendLocaleCode = languageMapping?.find(item => item.default)?.backendLocaleCode

// 获取服务端key
export const getBackendLocaleCode = (langCode)  => {
  return languageMapping?.find(item => item.langCode == langCode)?.backendLocaleCode ?? defaultBackendLocaleCode
}
export const getCmsLocaleByLangCode = (langCode) => {
  const item = languageMapping.find(item => item.langCode === langCode);
  return item ? item.cmsLocale : defaultMapping?.cmsLocale ?? 'en';
}



// 通过url获取语言
export const getLanguageByPath = (path) => {
  const langKey = path?.split('/')?.[1]
  const current =  languageMapping.find(item => item.localeCodes.some(code => isEqualIgnoreCase(code, langKey)))
  return current
}

// 移除url上的语言
export const removeUrlLanguage = (path) => {
  const current = getLanguageByPath(path)
  // url 上没有语言直接返回
  if (!current) {
    return path
  }
  return '/' + path.split('/').slice(2).join('/')
}

/**
 * 修改url上的语言
 * 考虑异常场景 英语不加语言前缀
 * @param {*} path
 * @param {*} langCode
 */
export const changeUrlLanguage = (path, langCode) => {
  const langKey = getLanguageKeyByLangCode(langCode)
  // 移除url上的语言
  let url = removeUrlLanguage(path)
  // 英语不加语言前缀
  if (langCode === langCodeMap.en) {
    return url
  }
  return `/${langKey}${url}`
}

/**
 * 判断语言代码是否为简体中文（zh-CN）或繁体中文（zh-TW）
 * @param {string} localeCode - 要判断的语言代码
 * @returns {boolean} - 如果是 zh-CN 或 zh-TW，则返回 true；否则返回 false
 */
export const isSimplifiedOrTraditionalChinese = (localeCode) => {
  if (!localeCode || typeof localeCode !== 'string') {
    return false;
  }

  return isEqualIgnoreCase(localeCode, languageKeyMap.zhCN) || isEqualIgnoreCase(localeCode, languageKeyMap.zhTW);
}
